$row-height: 30px;

.selectDropdown {
  width: 100%;
  margin: 4px auto 0;
}

%input {
  font-size: 0.9rem;
  width: 70;
  vertical-align: middle;
  display: inline-block;
  height: $row-height;
  border: 0;
  background: transparent;
  color: #444;
}

.inputName {
  @extend %input;
  width: 250px;
  margin-left: 10px;
}

.folderName {
  @extend %input;
  width: 250px;
  margin-left: 10px;
  font-weight: 600;
}

.inputNumber {
  @extend %input;
  text-align: right;
  width: 90px;
  display: inline-block;
}

.inputNumberLabel {
  margin-top: 7px;
  margin-left: 5px;
  float: left;
}

.inputNumberLabelRight {
  display: inline-block;
  margin-right: 5px;
  float: right;
}

.inputQuantity {
  @extend %input;
  width: 40px;
}

%nonEditable {
  height: $row-height;
  //background: rgba(0, 0, 0, 0.04);
  color: #666;
}

%editable {
  height: $row-height;
  /*background: rgba(0, 0, 0, 0.03);*/
  color: #111;
}

.nonEditableCell {
  @extend %nonEditable;
  display: inline-block !important;
}

.editableCell {
  @extend %editable;
  display: inline-block !important;
}

.editableInput {
  @extend %editable;
  padding-left: 5px;
}

.totalCell {
  //background: #eaeaea;
  //border-bottom: 1px solid #ccc;
}

.totalLabelNoTax {
  background: #17a2b8;
  color: #fff;
  padding: 2px 3px;
  border-radius: 5px;
  margin-top: 5px;
  margin-left: 2px;
  position: relative;
  display: inline-block;

  .tooltiptext {
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 8px;
    border-radius: 6px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;

    position: absolute;
    z-index: 999;
    top: -2px;
    right: 145%;
  }

  .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%; /* To the right of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
  }
}

/* Show the tooltip text when you mouse over the tooltip container */
.totalLabelNoTax:hover .tooltiptext {
  opacity: 1;
  visibility: visible;
}

.rowMenuButton {
  &:hover,
  &:focus {
    box-shadow: none !important;
    background: #fff;
  }
}

.tableProduct {
  font-size: 0.7rem !important;
  line-height: 0.7rem !important;
  vertical-align: sub;
  text-align: left !important;
  width: 200px !important;
}

.tableCell {
  display: inline-block;
  font-size: 0.9rem;
  width: 120px;
  padding-left: 3px;
  padding-right: 3px;
  border-right: 1px solid #ccc;
  height: $row-height;
  vertical-align: top;
  text-align: right;
}
.productCell {
  overflow: hidden;
  padding-top: 9px;
}

.productCodeLink {
  font-size: 0.6rem;
  float: right;
  margin-top: 10px;
  margin-right: 5px;
}
