.listNavPillsContainer {
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  .listNavPillsFlex::after {
    width: 60px;
    height: 45px;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
  }

  .listNavPillsFlex {
    flex-grow: 1;
    display: flex;
    position: relative;

    .listNavPills {
      width: 100%;
      overflow-x: scroll;
      display: block;
      white-space: nowrap;
      padding-right: 50px;

      li {
        display: inline-block;
      }
    }
  }
}

.buttonPair {
  float: right;
  width: 200px;
  margin-left: 20px;
  text-align: right;
  margin-top: 3px;
}
