.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.selected td {
  background-color: #007bff;
  color: #fff;
}

.expiresPicker {
  border: none;
  padding-top: 3px;
}

.modalbody {
  max-height: 600px;
  overflow-y: scroll;
}

.table th {
  background: #f6f6f6;
  color: #444;
}

.userSearch {
  height: calc(2.2rem + 2px);
  margin-bottom: 5px;
  flex: 6;
  margin-right: 20px;
}

.selectAllNoneButton {
  height: calc(2.2rem + 2px);
  flex: 1;
}

.form {
  display: flex;
  flex-direction: row;
}
