@import "App/vendor/argon/scss/argon-dashboard/custom/_colors";

.productName {
  font-size: 0.8rem;
  white-space: pre-line;
}

.productColumn {
  max-width: 200px;
}

.importCheckbox {
  text-align: center;
}

.importCheckboxTd {
  width: 50px;
  min-width: 30px !important;
}

.optionsTd {
  width: 45%;
}

tr.noImport td {
  opacity: 0.5;
}

.errorList {
  white-space: pre-line;
  display: inline-block;
}

.statusHeader {
  padding: 5px 10px;
  border-radius: 5px;
}

.statusHeader.success {
  background: $success;
  color: $white;
}
.statusHeader.warning {
  background: $warning;
  color: $gray-800;
}
.statusHeader.error {
  background: $danger;
  color: $white;
}

.pagination {
  float: right;
  margin-right: 10px;
  select {
    margin-right: 8px;
    min-width: 100px;
  }
  button {
    padding: 3px 5px;
  }
}

.showHideHeader {
  float: left;
  cursor: pointer;
}

.selectAllOrNone {
  float: right;
  padding: 3px 5px;
}
