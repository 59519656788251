.button {
  background: #eee;
  height: 35px;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  margin-left: 5px;
  cusor: pointer;
}

.icon {
  margin-right: 5px;
}
