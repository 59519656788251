$row-height: 30px;

.tableContainer {
  height: 600px;
  min-width: 1450px;
  color: #555;
  padding-bottom: 50;
}

.header {
  width: 100%;
  //background: #eee;
  color: #333;
  padding: 2px 0;
  font-size: 1rem;
  border-bottom: 3px solid #ccc;
  box-shadow: 0px 5px 3px 0px #f1f1f1;
}

.headerCell {
  display: inline-block;
  //border-right: 1px solid #ccc;
  padding-left: 3px;
  padding-right: 3px;
  overflow-y: hidden;
  width: 120px;
  text-align: center;
}

.headerRowPad {
  width: 460px !important;
}

.headerProduct,
.tableProduct {
  width: 200px !important;
}

.interactiveHeader {
  border-bottom: 1px dashed #17a2b8;
  padding-bottom: 1px;
}

.dragSourceButton {
  background: #eee;
  height: 35px;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  margin-left: 5px;
  cusor: pointer;
}

.dragSourceButtonIcon {
  margin-right: 5px;
}

.pickerSearchResults {
  position: absolute;
  z-index: 99;
  width: 94%;
  background: #fff;
  padding: 5px;
  border: 1px solid #ccc;
  margin-top: -3px;
  border-radius: 0 0 4px 4px;
  border-top-width: 0;
  box-shadow: 0px 3px 12px #00000017;
}

.dragSourcePickerResult {
  background: #fff;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  padding: 3px 5px;
}

.dragSourcePickerResult:last-child {
  border-bottom-width: 0;
}

%dragSourceTextLines {
  padding: 0;
  margin: 0;
  color: #111;
}

.dragSourceItemTitle {
  @extend %dragSourceTextLines;
}

.dragSourceItemSubtitle {
  @extend %dragSourceTextLines;
}

.dragSourceItemSmall {
  @extend %dragSourceTextLines;
  font-size: 0.7rem;
  font-weight: 500;
}
