.sequenceNumber {
  width: 25px;
  height: 25px;
  float: left;
  border-radius: 100%;
  text-align: center;
  font-size: 1rem;
  color: #fff;
  border: 1px solid #575a5f;
  background: #6c747d;
  margin: 2px 15px -2px 0;
}

.rowDivider {
  border-top: 1px solid #dadada;
  margin-top: 10px;
  padding-top: 20px;
}

.tabPane {
  padding-top: 20px;
  max-height: 85vh;
  overflow-y: scroll;
}

.numberAlign {
  margin-left: 40px;
}

.datePicker {
  padding: 4px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  color: #555;
  margin: 8px 0;
}

$borderColor: #ddd;

.paperTable {
  border: 1px solid $borderColor;
  border-collapse: collapse;
  width: 100%;

  th {
    background: #f5f5f5;
    border-top: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
    max-width: 50%;
    overflow: hidden;

    em {
      display: block;
      font-size: 0.7rem;
      font-style: normal;
      font-weight: 400;
      color: rgba(#999999, 0.57);
      text-transform: uppercase;
      letter-spacing: 0.3px;
    }
    .exampleContent {
      display: block;
      font-size: 0.8rem;
      width: 150px;
      word-break: break-all;
      font-weight: 400;
    }
  }

  th,
  td {
    min-width: 100px;
    padding: 10px;
  }

  th:not(:last-child),
  td:not(:last-child) {
    border-right: 1px solid $borderColor;
  }
}
