.result {
  background: #fff;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  padding: 3px 5px;
}

.result:last-child {
  border-bottom-width: 0;
}

%dragSourceTextLines {
  padding: 0;
  margin: 0;
  color: #111;
}

.title {
  @extend %dragSourceTextLines;
  max-width: 80%;
}

.subtitle {
  @extend %dragSourceTextLines;
}

.priceInline {
  display: inline-block;
  margin-right: 10px;
}

.highlighted {
  background: #007bff;
  color: #fff;

  :global(.badge) {
    background: #fff;
    color: #007bff;
  }

  .highlightWhiteText,
  .priceInline {
    color: #fff !important;
  }
}

.statusBadge {
  padding: 3px 10px 2px 10px;
  min-width: 70px;
  margin: 0px 10px 0 0;
  position: relative;
  top: -1px;
  left: 2px;
}

.bottomRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .priceIcon {
    margin-left: 2px;
  }
}

.small {
  @extend %dragSourceTextLines;
  font-size: 0.7rem;
  font-weight: 500;
}

.stockInfo {
  @extend %dragSourceTextLines;
  font-size: 0.7rem;
  font-weight: 500;
  align-self: flex-end;
  background: #eee;
  border-radius: 4px;
  padding: 2px 8px;
  text-align: right;

  .stockCount:first-child {
    margin-left: 3px;
  }

  .stockCount {
    display: inline-block;
    margin: -3px 3px 0 5px;
    display: inline-block;
  }

  .stockIcon {
    margin-right: 4px;
    display: inline-block;
  }
}

.stockTooltipContent {
  padding: 5px;
  min-width: 200px;
  text-align: left;
  font-size: 0.8rem;

  .stockTooltipHeader:first-child {
    margin-top: 0;
  }

  .stockTooltipHeader {
    display: block;
    color: #999;
    margin-top: 10px;
  }

  ul {
    list-style: none;
    margin: 0 0 5px 3px;
    padding: 0 0 0 5px;
    border-left: 1px solid #ccc;
  }
}

.availableCountForDepot {
  @extend %dragSourceTextLines;
  font-size: 0.8rem;
  font-weight: 500;
  align-self: flex-end;
  text-align: right;
  margin-top: -3px;
}
