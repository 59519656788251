@import "App/vendor/argon/scss/argon-dashboard/custom/_colors";

.emailList {
  max-height: 500px;
  overflow-y: scroll;
}

.email {
  background: #ffffff;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 15px 20px 20px;
  border-top-width: 5px;
}

.statusRow {
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
  flex-direction: row;
}

.statusIcon {
  float: left;
  margin: 5px 10px 0 5px;
}

.statusTitle {
  margin: 0;
  padding: 0;
  font-weight: 400;
  display: inline-block;
  font-size: 1rem;
  margin-right: 5px;
}

.statusMore {
  display: inline-block;
  font-size: 0.8rem;
  color: #999;
}

.bigSubject {
  padding-left: 10px;
}

.fieldGroup {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  align-items: center;
}

.fieldLabel {
  margin-right: 10px;
  width: 80px;
  text-align: right;
  color: #666;
  align-self: flex-start;
  margin-top: 10px;
}

.fieldInput {
  width: 100%;
}

.subjectInput {
  height: 40px;
}

.messageEditorContainer {
  margin: 30px 0 20px 80px;
}

.placeholderNoSelectionContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  min-height: 100px;
  padding: 50px 20px;
}

.placeholderIcon {
  font-size: 3rem;
  color: #ccc;
  margin: auto;
}

.placeholderTitle {
  font-size: 1.5rem;
  font-weight: 300;
  color: #ccc;
  margin: auto;
}

.actionsRow {
  margin: 30px 0 10px 80px;
  display: flex;
  justify-content: space-between;
}

.uploadDropper {
  display: block;
  background: #eee;
  text-align: center;
  padding: 20px 20px;
  border-radius: 10px;
  margin: 5px 0 15px 0;

  p {
    width: 100%;
    margin: auto;
    color: #999;
  }
}

.attachmentList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;

  li {
    margin: 0;
    width: 100%;
    padding: 5px;
    border-bottom: 1px solid #ddd;
    flex-direction: row;
    flex: 1;

    .attachmentOptions {
      flex: 1;

      button {
        margin-right: 0;
      }
    }

    .error {
      color: #c71414;
      display: block;
      font-weight: 600;
    }
  }

  li.uploading {
    font-size: 0.9rem;
    color: #999;
  }

  li:hover {
    background: #f9f9f9;
  }
}

.fileName {
  font-size: 0.9rem;
}

.fileSize {
  color: #999;
  margin-left: 10px;
}

.deleteAttachmentButton {
  color: $danger;
}

.readOnlyQuill {
  :global(.ql-toolbar) {
    display: none;
  }

  :global(.ql-editor) {
    border-top: 1px solid rgb(204, 204, 204);
  }
}

.noAttachments {
  color: #999;
  font-size: 0.8rem;
  margin-top: 10px;
  display: block;
  margin-left: 10px;
}
