.listTotal {
  display: inline-block;
  margin-right: 30px;
  min-width: 110px;
}

.listTotal.interactiveListTotal {
  border-bottom: 1px dashed #17a2b8;
}

.listTotal.clickableListTotal {
  cursor: pointer;
}

.listTotalsRow {
  background: #f1f1f1;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  color: #333;
  font-size: 0.9rem;
}

.listVersionTotalsRow {
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  color: #555;
  font-size: 0.9rem;
}
