div.avatar {
  text-align: center;
}

.text {
}

.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
