.filepond {
  box-sizing: border-box;
  position: relative;
  margin-bottom: 1em;
  font-size: 0.7rem;
  line-height: normal;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: 450;
  text-align: left;
  text-rendering: optimizeLegibility;
  direction: ltr;
  contain: layout style size;
}
