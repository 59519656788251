/*@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100&display=swap");

#navbar-main {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
}

span.jobPill {
  min-width: 3rem;
  padding: 3px 8px;
  border-radius: 5px;
  font-size: 0.8rem;
}

tr.jobRow td:not(:last-child) {
  cursor: pointer;
}

tr.jobRow:hover td {
  background: #f3f3f3;
}

span.red.input-group-text {
  border-color: #fb6340;
}

.formModal .modal-body .form-group {
  margin-bottom: 1rem;
}

.formModal .modal-body .form-group label {
  margin-bottom: 0.5rem;
}

.formModal .modal-body {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.suggestInput.is-invalid .suggestInput__control {
  border: 1px solid #fb6340;
}

.suggestInput.is-valid .suggestInput__control {
  border: 1px solid #2dce89;
}

.suggestInputFormGroup .feedback {
  display: block;
}

ul.nav.nav-tabs > .nav-item > .nav-link {
  cursor: pointer;
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.main-tabs .nav-link {
  color: #ccc;
}

.main-tabs .nav-link:not(.active):hover {
  color: #fff;
  border-color: rgba(0, 0, 0, 0) !important;
}

.nav-link.active:before {
  border-left: 4px solid #fac41d !important;
}

.main-tabs a.nav-link.active:hover {
  color: #333 !important;
}

.main-tabs a.nav-link.active {
  background: #fff !important;
}

.main-tabs {
  margin-top: -33px;
  border: 0;
  color: "#fff";
}

.MuiPaper-root *:not(i) {
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", Helvetica, Arial,
    sans-serif !important;
}

span.ReactPasswordStrength-strength-desc {
  font-size: 0.8rem;
  width: 100px;
  font-style: normal;
  top: 4px;
  right: 3px;
}

.ReactPasswordStrength-strength-bar {
  left: 1%;
  max-width: 98%;
  top: 2px;
  height: 4px;
}

.modal .react-datepicker__portal {
  width: 100% !important;
  height: 100% !important;
  border-radius: 20 !important;
}

.modal .react-datepicker__day {
  font-size: 1rem !important;
}

.modal .react-datepicker__input-container > input {
  width: 100%;
}

.modal-big-ass {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  padding: 5%;
  margin: 0;
}

ul.nav.steps.nav-tabs > .nav-item > .nav-link {
  cursor: default !important;
}

.steps.nav-tabs .nav-link:not(.active):hover,
.steps.nav-tabs .nav-link:not(.active):focus {
  border: 0 !important;
  margin-top: 1px;
  margin-left: 1px;
  margin-right: 1px;
}

tr.MuiTableRow-root:empty {
  display: none;
}

tbody.MuiTableBody-root {
  display: block;
  max-height: 60vh;
  min-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}

thead.MuiTableHead-root,
tbody.MuiTableBody-root tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

span.statusBlob {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: -1px;
}

table.headersInRows tbody tr th {
  background: #fafafa;
  color: #555;
}

.ListGroupItemIcon {
  margin-right: 10px;
  min-width: 30px;
}

.toastText {
  font-size: 0.85rem;
  line-height: 1rem;
}

.toastText svg {
  margin-right: 10px;
  float: left;
  height: 100%;
  margin: 2px 10px 25px 5px;
}

.Toastify__toast {
  box-shadow: none;
  border-radius: 5px;
}

ul.smallListGroup li {
  padding: 0.3rem;
}

.cursor-pointer {
  cursor: pointer;
}

.MuiPaper-elevation2 {
  box-shadow: none !important;
}

hr {
  margin: 1rem 0;
}

table.grayHeaders thead tr th {
  background: #eee;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.smallListGroupItemHeight {
  padding: 8px 16px;
}

.ListGroupWithHeader li {
  color: #2d2d2d;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
}

.ListGroupWithHeader li:last-child {
  border-bottom-color: #d5d5d5;
  border-left-color: #d5d5d5;
  border-right-color: #d5d5d5;
}

.ListGroupWithHeader li {
  border-color: #dee0e1;
  border-left-color: #d5d5d5;
  border-right-color: #d5d5d5;
}

.ListGroupItemHeader {
  background: #f7f7f7;
  border-bottom-width: 2px;
  border-top-color: #d5d5d5;
  border-left-color: #d5d5d5;
  border-right-color: #d5d5d5;
}

.ListGroupItemFooter {
  background: #f7f7f7;
  border-top-width: 1px;
  border-bottom-color: #d5d5d5;
  border-left-color: #d5d5d5;
  border-right-color: #d5d5d5;
}

.list-group-item.active {
  z-index: initial !important;
}

.error-border {
  border: 1px solid red !important;
}
.success-border {
  border: 1px solid green !important;
}

.modal-big-ass .modal-content .modal-body {
  max-height: 80vh;
  overflow-y: scroll;
}

.FilePondGrid .filepond--item {
  width: calc(50% - 0.5em);
}

.FilePondGrid .filepond--root {
  max-height: 200px;
  overflow-y: scroll;
}

.FilePondGrid .filepond--drop-label {
  height: 20px;
}
