.text {
  padding-right: 30px;
  line-height: 0.9rem;
  padding-top: 3px;
  text-align: left;
  font-weight: 400;
}

.button {
  padding: 0;
  /*box-shadow: 0px 1px 4px rgba(50, 50, 93, 0.11),
    -1px 0px 2px rgba(0, 0, 0, 0.08);*/
  border: 1px solid #eee;
}

.button:hover {
  border: 1px solid #ccc;
}
