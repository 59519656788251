.pickerSearchResults {
  border: 1px solid #ccc;
  margin-top: -3px;
  border-radius: 0 0 4px 4px;
  padding: 5px;
  background: #fff;
  position: absolute;
  z-index: 99;
  overflow-x: hidden;
  width: 100%;
  margin-right: 30px;
  box-shadow: 0px 8px 12px #00000017;
  border-top: 3px solid #ccc;
  top: 38px;
}
