// Disable selection
@mixin disable-selection {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.rowWrapper {
  height: 100%;
  box-sizing: border-box;
  cursor: default;
  //border-bottom: 1px solid #ccc;
  font-size: 0.9rem;
  line-height: 0.9rem;
  @include disable-selection;

  &:hover {
    /*opacity: 0.8;*/
  }

  &:active {
    opacity: 1;
  }
}

.rowWrapperDragDisabled {
  cursor: default;
}

.rowWrapperFolder {
  //background: #fffae2;
}

.row {
  height: 100%;
  white-space: nowrap;
  display: flex;
  position: relative;

  & > * {
    box-sizing: border-box;
  }
}

/**
 * The outline of where the element will go if dropped, displayed while dragging
 */
.rowLandingPad {
  border: none;
  box-shadow: none;
  outline: none;

  * {
    opacity: 0 !important;
  }

  &::before {
    background-color: lightblue;
    border: 2px dotted black;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
}

/**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */
.rowCancelPad {
  @extend .rowLandingPad;

  &::before {
    background-color: #e6a8ad;
  }
}

/**
 * Nodes matching the search conditions are highlighted
 */
.rowSearchMatch {
  box-shadow: inset 0 -7px 7px -3px #0080ff;
}

/**
 * The node that matches the search conditions and is currently focused
 */
.rowSearchFocus {
  box-shadow: inset 0 -7px 7px -3px #fc6421;
}

%rowItem {
  display: inline-block;
  vertical-align: middle;
}

.rowPad {
  width: 460px;
  overflow-x: hidden;
  border-right: 1px solid #ccc;
}

.rowContents,
.rowPad {
  @extend %rowItem;
  position: relative;
  height: 100%;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rowLabel {
  @extend %rowItem;
  flex: 0 1 auto;
  padding-right: 20px;
}

.rowToolbar {
  @extend %rowItem;
  flex: 0 1 auto;
  display: flex;
}

.toolbarButton {
  @extend %rowItem;
}

.rowContents,
.rowPad,
.rowLabel,
.rowToolbar,
.moveHandle,
.toolbarButton {
  display: inline-block;
  vertical-align: middle;
}

.moveHandle,
.loadingHandle {
  height: 100%;
  width: 30px;
  /*background: #d9d9d9
    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+")
    no-repeat center;
  border: solid #aaa 1px;
  box-shadow: 0 2px 2px -2px;*/
  cursor: move;
  z-index: 1;
}

.loadingHandle {
  cursor: default;
  background: #d9d9d9;
}

.collapseButton,
.expandButton {
  appearance: none;
  border: none;
  background: transparent;
  padding: 0 0 0 8px;
  z-index: 2;
  position: absolute;
  top: 45%;
  width: 30px;
  height: 30px;
  transform: translate3d(-50%, -50%, 0);
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    transform-origin: 7px 4px;
    transform: translate3d(-50%, -20%, 0);
    border: solid transparent 10px;
    border-left-width: 7px;
    border-right-width: 7px;
    border-top-color: gray;
  }

  &:hover::after {
    border-top-color: black;
  }

  &:focus {
    outline: none;

    &::after {
      filter: drop-shadow(0 0 1px #83bef9) drop-shadow(0 0 1px #83bef9)
        drop-shadow(0 0 1px #83bef9);
    }
  }
}

.expandButton::after {
  transform: translate3d(-50%, -20%, 0) rotateZ(-90deg);
}

/**
 * Line for under a node with children
 */
.lineChildren {
  height: 100%;
  display: inline-block;
}

/* ==========================================================================
   Scaffold

    Line-overlaid blocks used for showing the tree structure
   ========================================================================== */
.lineBlock {
  height: 100%;
  position: relative;
  display: inline-block;
  flex: 0 0 auto;
}

/*.lineBlock::after {
  content: "";
  position: absolute;
  background-color: black;
  width: 1px;
  left: 50%;
  bottom: 0;
  height: 10px;
}*/

.absoluteLineBlock {
  @extend .lineBlock;
  position: absolute;
  top: 0;
}

/* Highlight line for pointing to dragged row destination
   ========================================================================== */
$highlight-color: #36c2f6;
$highlight-line-size: 6px; // Make it an even number for clean rendering

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */
.highlightLineVertical {
  z-index: 3;

  &::before {
    position: absolute;
    content: "";
    background-color: $highlight-color;
    width: $highlight-line-size;
    margin-left: $highlight-line-size / -2;
    left: 50%;
    top: 0;
    height: 100%;
  }

  @keyframes arrow-pulse {
    $base-multiplier: 10;
    0% {
      transform: translate(0, 0);
      opacity: 0;
    }
    30% {
      transform: translate(0, 30% * $base-multiplier);
      opacity: 1;
    }
    70% {
      transform: translate(0, 70% * $base-multiplier);
      opacity: 1;
    }
    100% {
      transform: translate(0, 100% * $base-multiplier);
      opacity: 0;
    }
  }

  &::after {
    content: "";
    position: absolute;
    height: 0;
    margin-left: -1 * $highlight-line-size / 2;
    left: 50%;
    top: 0;
    border-left: $highlight-line-size / 2 solid transparent;
    border-right: $highlight-line-size / 2 solid transparent;
    border-top: $highlight-line-size / 2 solid white;
    animation: arrow-pulse 1s infinite linear both;
  }
}

/**
 * +-----+
 * |     |
 * |  +--+
 * |  |  |
 * +--+--+
 */
.highlightTopLeftCorner {
  &::before {
    z-index: 3;
    content: "";
    position: absolute;
    border-top: solid $highlight-line-size $highlight-color;
    border-left: solid $highlight-line-size $highlight-color;
    box-sizing: border-box;
    height: calc(50% + #{$highlight-line-size / 2});
    top: 50%;
    margin-top: $highlight-line-size / -2;
    right: 0;
    width: calc(50% + #{$highlight-line-size / 2});
  }
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  +->|
 * +-----+
 */
.highlightBottomLeftCorner {
  $arrow-size: 7px;
  z-index: 3;

  &::before {
    content: "";
    position: absolute;
    border-bottom: solid $highlight-line-size $highlight-color;
    border-left: solid $highlight-line-size $highlight-color;
    box-sizing: border-box;
    height: calc(100% + #{$highlight-line-size / 2});
    top: 0;
    right: $arrow-size;
    width: calc(50% - #{$arrow-size - ($highlight-line-size / 2)});
  }

  &::after {
    content: "";
    position: absolute;
    height: 0;
    right: 0;
    top: 100%;
    margin-top: -1 * $arrow-size;
    border-top: $arrow-size solid transparent;
    border-bottom: $arrow-size solid transparent;
    border-left: $arrow-size solid $highlight-color;
  }
}
