.lineItem {
  padding: 0.7rem 1rem;
  border-bottom: 1px solid #ddd;
}

.newQuantity,
.details {
  vertical-align: middle;
}

.newQuantity {
  display: inline-block;
  padding-right: 2rem;
}

.details {
  display: inline-block;
}

.quantityAdjustButton {
  height: 30px;
  width: 30px;
  padding: 0;
  border-radius: 100%;
}

.quantityInput {
  width: 60px;
  text-align: center;
  display: inline-block;
  border: 0;
}

.stockAllocationContainer {
  background: #f7f7f7;
  border-radius: 5px;
  display: block;
  border: 1px solid #ddd;
  padding: 0.5rem;
  margin: 15px 0 0;
  list-style: none;
}

.stockAllocationLine {
  border-bottom: 1px solid #ccc;
  padding: 0.5rem;
}

.stockAllocationLine:last-child {
  border: 0;
}

.formBlock {
  display: flex;
  margin-right: 60px;

  input[type="text"] {
    padding: 5px 10px;
    height: 30px;
    margin: 0 10px;
  }
}
