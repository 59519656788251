.draggableFieldBadge {
  margin-right: 5px;
  margin-bottom: 2px;
  font-size: 0.7rem !important;
}

.draggableFieldBadgeRequired {
  margin-left: 5px;
  font-size: 0.5rem;
  font-weight: 400;
}

p {
  width: 70%;
}

.optionHolder {
  margin-bottom: 20px;
}

td.droppable {
  min-height: 50px;
}

.droppableZone {
  min-width: 200px;
  min-height: 50px;
}

.forceWidth {
  width: 100%;
  float: left;
}

.previewTable tbody tr {
  td,
  th {
    padding: 5px;
  }
}
