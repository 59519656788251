.node {
  min-width: 100%;
  position: relative;
  cursor: default;
}

.node:nth-of-type(odd) {
  background: rgba(0, 0, 4, 0.03);
}

.node:hover {
  background: #c7dff9;
}

.node.selected {
  background: #007bff;

  *:not(:global(.dropdown-item)):not(:global(.tooltiptext)):not(select):not(option) {
    color: #fff !important;
    ::after {
      border-top-color: #fff;
    }
  }
}
