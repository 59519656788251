@import "App/vendor/argon/scss/argon-dashboard/custom/_colors";

.supplierDetail {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #ddd;
}

:global(.list-group-item):global(.border-secondary):global(.active) {
  background-color: $secondary;
}
:global(.list-group-item):global(.border-success):global(.active) {
  background-color: $success;
}
:global(.list-group-item):global(.border-warning):global(.active) {
  background-color: $warning;
}
:global(.list-group-item):global(.border-danger):global(.active) {
  background-color: $danger;
}

:global(.active-all-white):global(.active) {
  * {
    color: #fff !important;
  }
}
